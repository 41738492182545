import "./App.css";
import React, { useReducer, useMemo } from "react";
import { BrowserRouter as Router, MemoryRouter, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";

import { Home } from "./pages/home";
import { Form } from "./pages/form";

import { Dispatch, reducer } from "./hooks/hooks";
import { Escolha } from "./pages/escolha";
import { Pagamento } from "./pages/pagamento";
import { Rodape } from "./components/rodape";
import { Ingresso } from "./pages/ingresso";
import { Switch } from "react-router-dom/cjs/react-router-dom";
import Top from "./components/top";

if (process.env.NODE_ENV !== "development") console.log = () => {};

function App() {
  const reducerData = useReducer(reducer, {
    userdata: {
      name: "",
      email: "",
      cpfCnpf: "",
      mobilePhone: "",
      customer: "",
    },
    step: 0,
    validado: false,
    modal: false,
  });
  const [{}, dispatch] = reducerData;

  const name = localStorage.getItem("name") || "";
  const email = localStorage.getItem("email") || "";
  const cpfCnpj = localStorage.getItem("cpfCnpj") || "";
  const mobilePhone = localStorage.getItem("mobilePhone") || "";
  const customer = localStorage.getItem("customer") || "";
  const validado = false;

  useSalvaReducer("userdata", {
    name,
    email,
    cpfCnpj,
    mobilePhone,
    customer,
  });

  useSalvaReducer("validado", validado);
  useSalvaReducer("modal", false);

  function useSalvaReducer(type, payload) {
    const salva = useMemo(
      () =>
        dispatch({
          type: type,
          payload: payload,
        }),
      []
    );
    // ...
  }

  return (
    <div>
      <Dispatch.Provider value={reducerData}>
        <Container>
          <Router>
            <Switch>
              <Route exact path="/">
                <MemoryRouter initialEntries={["/home"]}>
                  <Route component={Top} />
                  <Route path="/home" component={Home} />
                  <Route path="/form" component={Form} />
                  <Route path="/escolha" component={Escolha} />
                  <Route path="/pagamento" component={Pagamento} />
                </MemoryRouter>
              </Route>
              <Route path="/ingresso/:token" component={Ingresso} />
              <Rodape />
            </Switch>
          </Router>
        </Container>
      </Dispatch.Provider>
    </div>
  );
}

export default App;
