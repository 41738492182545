import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { useSpring, animated } from "react-spring";
import useWindowDimensions, {
  Dispatch,
  aviso,
  navigateTo,
  postServerGeraPix,
  sendCelularMessage,
} from "../hooks/hooks";
import {
  Button,
  ButtonContent,
  Container,
  Divider,
  Header,
  Icon,
  Image,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
  ModalHeader,
} from "semantic-ui-react";

export const Escolha = (props) => {
  const [open, setOpen] = useState(false);
  const [periodo, setPeriodo] = useState("Tarde");
  const [loading, setLoading] = useState(false);
  const { mobile } = useWindowDimensions();

  const [play, toggle] = useState(true);

  const reducer = useContext(Dispatch);
  const [{ userdata, ingressos }] = reducer;

  const { x, y } = useSpring({
    from: { y: play ? -window.innerHeight : 0, opacity: play ? 0 : 1 },
    to: { y: play ? 0 : -window.innerHeight, opacity: play ? 1 : 0 },
    onRest: () => !play && props.history.push("/pagamento"),
  });

  useEffect(() => {
    console.log(ingressos);
  }, [ingressos]);

  const geraCompra = async (ingresso) => {
    setLoading(true);
    console.log(ingresso);
    try {
      const response = await postServerGeraPix({
        customer: userdata.customer,
        period: ingresso.period,
        value: ingresso.value,
        billingType: ingresso.billingType,
        description: ingresso.description,
        validation_token: "klkasjMOSmOAMsFsm",
      });
      console.log(response);

      const resposta = await sendCelularMessage(
        "55" + userdata.mobilePhone,
        userdata.mobilePhone,
        `Agradecemos seu interesse no evento do Ano Novo Chinês no Ton Hoi.\nFalta muito pouco: segue abaixo seu link para garantir esta entrada INDIVIDUAL para um dia inesquecível.\n\n(Caso não esteja visualizando o link, por favor, salve este contato que automaticamente o link ficará disponível)\nAbaixo, está o seu link de pagamento\n${response.data.invoiceUrl}\n\n(Sim, o pagamento será através de nosso parceiro Asaas)`
      );

      toggle(!play);
    } catch (err) {
      aviso("Erro em gerar o link de cobrança, tente novamente", "error");
      setLoading(false);
    }
  };

  const escolhePeriodo = (e, a) => {
    console.log(a);
    setOpen(true);
    setPeriodo(a.children.split(" ")[2]);
  };

  const getPropsByValues = (period, type) => {
    const results = ingressos.filter((element) => {
      // 👇️ using AND (&&) operator
      return (
        element.period === period.toLowerCase() && element.billingType === type
      );
    });
    console.log(results, period, type);
    return results[0];
  };

  return (
    <div className="frame">
      <div className="central">
        <animated.div
          style={Object.assign({ x, y, width: mobile ? "100%" : 500 })}
        >
          <div className="pages">
            <Container fluid>
              <Header as="h2">Períodos</Header>
              <Divider />
              <div style={{ marginTop: 5, fontSize: 16 }}>
                A festa acontecerá em dois períodos, tarde e noite.
                <br />
                Escolha um deles para saber mais informações e comprar seu
                ingresso.
              </div>
            </Container>
            <div
              style={{
                fontSize: 20,
                lineHeight: 1,
                color: "#000000",
              }}
            ></div>

            <Button
              style={{ marginTop: 10 }}
              primary
              onClick={escolhePeriodo}
              fluid
              size="big"
            >
              {"Período da Tarde"}
            </Button>
            <Button
              style={{ marginTop: 10 }}
              onClick={escolhePeriodo}
              fluid
              secondary
              size="big"
            >
              {"Período da Noite"}
            </Button>
          </div>
        </animated.div>

        <Modal
          closeIcon
          onClose={() => setOpen(false)}
          open={open}
          trigger={<div></div>}
        >
          <ModalHeader>{`Ingresso da ${periodo}`}</ModalHeader>
          <ModalContent>
            <ModalDescription>
              <Header style={{ marginTop: -10 }}>Sobre o convite</Header>
              <Container textAlign="justified">
                <p>
                  Este convite garante sua entrada para o evento no horário
                  comprado e indicado (tarde ou noite) para aproveitar conosco:
                </p>
                <p>
                  Menu harmonizado (wan tan frito, palza de porco, macarrão com
                  camarão, barriga de porco, bifum com curry vegetariano, porco
                  doce azedo); 6 rótulos de vinhos (entre branco, rosé e tinto)
                  refrigerantes e água; show de dança; show de bossa/jazz; praça
                  para degustação de outros novos rótulos de vinhos (que
                  entrarão em nossa nova carta) com nossos novos parceiros, além
                  da venda com desconto especial, somente para o Ano Novo).
                </p>
                <p>
                  O serviço neste evento será totalmente volante (os garçons
                  circularão por todo salão - não haverão mesas).
                </p>
              </Container>
            </ModalDescription>
          </ModalContent>
          <ModalActions>
            <Button as="div" labelPosition="right">
              <Button
                color="green"
                disabled={loading}
                loading={loading}
                onClick={() => geraCompra(getPropsByValues(periodo, "PIX"))}
                style={{
                  width: 170,
                  lineHeight: 1.7,
                  justifyContent: "center",
                }}
              >
                <Icon name="shop" />
                {loading ? "Aguarde" : "Pagar no PIX"}
              </Button>
              <Label as="a" basic color="green" pointing="left">
                {`R$ ${getPropsByValues(periodo, "PIX").value}`}
              </Label>
            </Button>
            <Button as="div" labelPosition="right">
              <Button
                fluid
                color="green"
                disabled={loading}
                loading={loading}
                onClick={() =>
                  geraCompra(getPropsByValues(periodo, "CREDIT_CARD"))
                }
                style={{ width: 170, lineHeight: 1.7 }}
              >
                <Icon name="shop" />
                {loading ? "Aguarde" : "Pagar no crédito"}
              </Button>
              <Label as="a" basic color="green" pointing="left">
                {`R$ ${getPropsByValues(periodo, "CREDIT_CARD").value}`}
              </Label>
            </Button>
          </ModalActions>
        </Modal>
      </div>
    </div>
  );
};
