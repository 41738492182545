import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { navigateTo } from "../hooks/hooks";

export const Header = (props) => {
  const [banner, setBanner] = useState(1);

  const params = useParams();

  useEffect(() => {
    console.log(params);
    if (params?.qr) {
      props.history.push("/ingresso", { params });
    }
  }, [params]);

  useEffect(() => {
    const image = Math.round(Math.random()) + 1;
    setBanner(image);
  }, []);

  const ImageHeader = () => {
    return (
      <div>
        <div
          className="ImageHeader"
          style={{
            backgroundImage: `url("/images/banner-aprovado-${banner}.jpg")`,
            position: "absolute",
            top: 0,
            width: "100%",
            backgroundSize: 1600,
            height: 125,
          }}
        />
      </div>
    );
  };

  const memoizedHeader = useMemo(() => <ImageHeader />, [banner]);

  return (
    <div className="frame">
      <ToastContainer />
      <div className="top" style={{ height: 125 }}>
        {/*<div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ height: "35%" }}>
            <div style={{ height: "100%", fontSize: 18, cursor: "pointer" }}>
              Ton Hoi - Restaurante
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image src="/images/logo.png" style={{ height: "100%" }} />
          </div>
        </div>*/}
        {memoizedHeader}
      </div>
    </div>
  );
};
