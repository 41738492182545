import { Header } from "../pages/header";
import { Steps } from "./steps";

const Top = () => {
  return (
    <>
      <Header />
      <Steps />
    </>
  );
};

export default Top;
