import React, { useEffect, useState } from "react";
import mergeImages from "merge-images";
import _ from "lodash";
import { decrypt, encrypt } from "crypto-urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Message, MessageHeader } from "semantic-ui-react";
import useWindowDimensions from "../hooks/hooks";

const url = window.location.origin;

export const Ingresso = () => {
  const [img, setImg] = useState();
  const [erro, setErro] = useState(false);
  const { windowDimensions } = useWindowDimensions();

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname) {
      //var path =
      // "/ingresso/U2FsdGVkX1p1L2u3SkcjMTa7obvos1L2a3S4hVHlKztxv5xIip1L2u3ShFqNTs1L2a3S4h8e1Q2u3A4l/U2FsdGVkX19Ro2Rz3drmVzf22oaJYKH9QylT9gyKbpIe1Q2u3A4l/U2FsdGVkX1p1L2u3Skjejs1L2a3S4hTzGgYGHy7MgyXdhq6YJHWUcTFe0e1Q2u3A4l/U2FsdGVkX19lBCXB2dxiQjMp1L2u3S34q4Wp1L2u3SHT3kfzTvxry4Ye1Q2u3A4l";
      var path = location?.pathname;
      let pattern = "/ingresso/:qr/:cpf/:period/:id";
      /*let patternObj = {
        ":qr": "O2O659",
        ":cpf": "32103183878",
        ":period": "noite",
      };

      let encryptedPath = encrypt.path(pattern, patternObj);
      console.log(encryptedPath);*/

      try {
        let decryptedData = decrypt.path(pattern, path);
        console.log(decryptedData);

        const props = decryptedData.split("/");
        console.log(props);
        if (props.length !== 6) {
          setErro(true);
        } else {
          generateImage(props);
        }
      } catch (err) {
        setErro(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (location) {
      console.log(location);
    }
  }, [location]);

  const generateImage = (decryptedData) => {
    const qrcodePath = `/qrcodes/${decryptedData[2]}.png`;
    mergeImages([
      `/images/ticket-${decryptedData[4]}.png`,
      { src: qrcodePath, x: 390, y: 1530 },
    ])
      .then((b64) => {
        /*var a = document.createElement("a");
      a.href = b64;
      a.download = "ingresso.png";
      a.click();*/
        setImg(b64);
        //console.log(b64);
      })
      .catch((err) => {
        console.log(qrcodePath, err);
        setErro(true);
      });
  };

  return (
    <div className="frame">
      {img && <img src={`${img}`} style={{ width: windowDimensions.width }} />}
      {erro && (
        <Message
          error
          header="Esse ingresso não existe, verifique novamente"
          list={[
            "O link pode ter sido copiado incompleto",
            "As informações dele estão incorretas",
          ]}
        />
      )}
    </div>
  );
};
