import React, { useContext, useEffect, useMemo, useRef } from "react";
import useWindowDimensions, { Dispatch } from "../hooks/hooks";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
} from "semantic-ui-react";
import { useLocation } from "react-router-dom";

export const Steps = () => {
  const reducer = useContext(Dispatch);
  const mobile = useWindowDimensions();
  const [{ step }, dispatch] = reducer;
  const pages = ["/home", "/form", "/escolha", "/pagamento"];

  let location = useLocation();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevCount = usePrevious(location.pathname);

  /*const memoLocation = useMemo(() => <SetStep />, [location]);

  const SetStep = () => {
    let index = pages.indexOf(location.pathname);
    return dispatch({
      type: "step",
      payload: index,
    });
  };*/

  const getStep = useMemo(() => {
    console.log(location, prevCount);
    let index = pages.indexOf(location.pathname);
    const loc = location;
    dispatch({
      type: "step",
      payload: index,
    });
    return index;
  }, [location]);

  const BreadCrumbs = ({ getStep }) => {
    console.log(getStep);
    return (
      <Breadcrumb size={mobile ? "large" : "big"}>
        <BreadcrumbSection active={getStep === 0}>validação</BreadcrumbSection>
        <BreadcrumbDivider icon="right chevron" />
        <BreadcrumbSection active={getStep === 1}>cadastro</BreadcrumbSection>
        <BreadcrumbDivider icon="right chevron" />
        <BreadcrumbSection active={getStep === 2}>ingresso</BreadcrumbSection>
        <BreadcrumbDivider icon="right chevron" />
        <BreadcrumbSection active={getStep === 3}>concluído</BreadcrumbSection>
      </Breadcrumb>
    );
  };

  const memoizedBreadcrumbs = useMemo(
    () => <BreadCrumbs getStep={getStep} />,
    [mobile, step]
  );

  return (
    <div className="frame">
      <div className="central">
        <div style={{ marginTop: 10 }}>{memoizedBreadcrumbs}</div>
      </div>
    </div>
  );
};
