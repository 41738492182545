import React, { useState, useContext, useRef } from "react";
import { Button, Input, Modal } from "semantic-ui-react";
import { Dispatch, aviso, sendCelularMessage } from "../hooks/hooks";
import _ from "lodash";

export const Validador = ({ open }) => {
  const reducer = useContext(Dispatch);
  const [{ userdata, modal }, dispatch] = reducer;

  const modalRef = useRef();

  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(open);
  const [dados, setDados] = useState({
    mobilePhone: userdata.mobilePhone,
    token: null,
    tokenresposta: null,
  });
  const { mobilePhone, token, tokenresposta } = dados;

  const salva = (e, a) => {
    if (a.name === "mobilePhone") {
      setDados((prev) => ({
        ...prev,
        mobilePhone: Math.max(0, parseInt(a.value)).toString().slice(0, 11),
      }));
    } else {
      setDados((prev) => ({
        ...prev,
        tokenresposta: a.value.toUpperCase(),
      }));
    }
  };

  const salvaDados = async () => {
    if (!token) {
      if (_.isEmpty(mobilePhone)) {
        aviso("Você precisa preencher o campo corretamente!", "info");
      } else if (mobilePhone.length < 11) {
        aviso("O telefone precisa incluir o DDD!", "info");
      } else {
        var randomNumber = _.times(1, () =>
          _.times(4, () => _.random(35).toString(36)).join("")
        ).join("-");
        let valNumber = randomNumber.toUpperCase();

        setLoading(true);

        const resposta = await sendCelularMessage(
          "55" + mobilePhone,
          mobilePhone + "_" + valNumber,
          "TON HOI Restaurante\n✅ Token de validação: " + valNumber
        );

        console.log(resposta);

        if (resposta.data.status === "PENDING") {
          setDados((prev) => ({
            ...prev,
            token: valNumber,
          }));

          //toast('Token enviado! Digite abaixo o código recebido em seu WhatsApp.', {type: toast.TYPE.SUCCESS, transition: Flip})
          aviso(
            "Token enviado! Digite acima o código recebido em seu WhatsApp.",
            "info"
          );
        } else {
          aviso("Erro no envio da mensagem", "error");
        }

        setLoading(false);
      }
    } else {
      if (token === tokenresposta) {
        setPopup(false);

        localStorage.setItem("validado", "true");
        localStorage.setItem("mobilePhone", mobilePhone);
        dispatch({
          type: "userdata",
          payload: { mobilePhone },
        });
        dispatch({
          type: "validado",
          payload: true,
        });
      } else {
        aviso("Token incorreto, tente novamente", "error");
      }
    }
  };

  const closePopup = () => {
    dispatch({
      type: "modal",
      payload: false,
    });
  };

  return (
    <Modal
      ref={modalRef}
      animation={"false"}
      size={"mini"}
      open={modal}
      closeIcon
      onClose={closePopup}
    >
      <Modal.Header>Digite seu celular</Modal.Header>
      <Modal.Content>
        <div>
          <p>
            O número será validado pelo WhatsApp para a confirmação da compra e
            envio do ingresso. Seu número será usado somente para informações
            dessa compra.
          </p>
          {/*<div><Input name='nome' fluid focus placeholder='Nome' onChange={salva}/></div>*/}
          <div style={{ marginTop: 5 }}>
            <Input
              name="mobilePhone"
              value={mobilePhone ? mobilePhone : ""}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              focus
              fluid
              placeholder="Celular com DDD"
              onChange={salva}
            />
          </div>
          {token && (
            <div style={{ marginTop: 15 }}>
              <Input
                name="token"
                fluid
                focus
                placeholder="Digite aqui o token"
                onChange={salva}
                value={tokenresposta ? tokenresposta : ""}
              />
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button positive disabled={loading} onClick={salvaDados}>
          {!token ? "Validar" : "Verificar"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
