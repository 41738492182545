import React, { useCallback, useEffect, useState } from "react";
import { useSpring } from "react-spring";

export const Rodape = () => {
  const [value, setValue] = useState(10);
  const onScroll = useCallback((e) => console.log(e), []);
  const [xpos, setXpos] = useSpring(() => ({ bottom: 10 }));

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    setXpos({ bottom: value - window.scrollY });
  };

  return (
    <div
      className="StickyBox"
      style={{ width: "100%", backgroundColor: "transparent", height: 60 }}
      onScroll={onScroll}
    >
      <div>
        <div
          className="ImageRodape"
          style={{
            position: "absolute",
            bottom: -20,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 12,
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            v0.5 beta
          </div>
        </div>
      </div>
    </div>
  );
};
