import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useSpring, animated } from "react-spring";
import { SubmitButton, Form as Fields, Input } from "formik-semantic-ui-react";

import useWindowDimensions, {
  Dispatch,
  aviso,
  navigateTo,
  postServerCadastro,
  validateCPF,
  validateFullName,
} from "../hooks/hooks";
import { Formik } from "formik";
import {
  Button,
  Container,
  Divider,
  Grid,
  GridColumn,
  Header,
  Icon,
  Label,
  LabelGroup,
  Popup,
} from "semantic-ui-react";

export const Form = (props) => {
  const [loading, setLoading] = useState(false);
  const [play, toggle] = useState(true);
  const [clear, setClear] = useState(false);
  const { mobile } = useWindowDimensions();

  const reducer = useContext(Dispatch);
  const [{ userdata }, dispatch] = reducer;

  useEffect(() => {
    const data = localStorage.getItem("name");
    if (data) {
      setClear(true);
    }
  }, []);

  const { x, y } = useSpring({
    from: { y: play ? -window.innerHeight : 0, opacity: play ? 0 : 1 },
    to: { y: play ? 0 : -window.innerHeight, opacity: play ? 1 : 0 },
    onRest: () => !play && props.history.push("/escolha"),
  });

  const defaultProps = {
    name: userdata.name,
    email: userdata.email,
    cpfCnpj: userdata.cpfCnpj ? Number(userdata.cpfCnpj) : "",
  };

  const validationSchema = Yup.object({
    cpfCnpj: Yup.number()
      .required("O número de cpf é obrigatório")
      .test(validateCPF),
    email: Yup.string()
      .email("Endereço de E-mail inválido")
      .required("Você precisa difitar um E-mail"),
    name: Yup.string().required("O nome é obrigatório").test(validateFullName),
  });

  const _handleSubmit = async (e) => {
    //console.log(e);
    !loading && validaEntrada(e);
  };

  const validaEntrada = async (e) => {
    setLoading(true);
    const mobilePhone = userdata.mobilePhone;
    const data = {
      ...e,
      mobilePhone,
      cpfCnpj: e.cpfCnpj.toString(),
    };
    try {
      const response = await postServerCadastro(data);

      console.log(response);
      const dados = {
        ...data,
        customer: response.data.customer,
      };

      localStorage.setItem("customer", response.data.customer);
      localStorage.setItem("name", dados.name);
      localStorage.setItem("email", dados.email);
      localStorage.setItem("cpfCnpj", dados.cpfCnpj);

      dispatch({
        type: "userdata",
        payload: dados,
      });

      dispatch({
        type: "ingressos",
        payload: response.data.ingressos,
      });

      toggle(!play);
    } catch (err) {
      aviso("Erro no cadastro\nVerifique os dados e tente novamente", "error");
      setLoading(false);
    }
  };

  const clearData = () => {
    localStorage.clear();
    window.location.reload();
  };

  const popup = () => {
    return (
      <Popup
        trigger={
          <LabelGroup color="blue">
            <Label as="a">
              Limpar
              <Icon name="close" />
            </Label>
          </LabelGroup>
        }
        flowing
        hoverable
        position="bottom right"
        on="click"
      >
        <Grid centered divided columns={1}>
          <GridColumn textAlign="center">
            <p>
              Esse botão limpará os dados abaixo
              <br />e começará um novo processo.
            </p>
            <Button onClick={clearData} primary>
              Confirmar
            </Button>
          </GridColumn>
        </Grid>
      </Popup>
    );
  };

  return (
    <div className="frame">
      <div className="central">
        <animated.div
          style={Object.assign({ x, y, width: mobile ? "100%" : 500 })}
        >
          <div className="pages" style={{ marginBottom: 50 }}>
            <Container fluid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: 20,
                }}
              >
                <Header as="h2">Cadastre seu dados</Header>
                {clear && popup()}
              </div>

              <Divider />
              <div style={{ fontSize: 20 }}>
                Para prosseguir com a compra, preciso do seu nome completo,
                email e cpf.
              </div>
            </Container>

            <div style={{ marginTop: 15 }}>
              <Formik
                initialValues={defaultProps}
                validationSchema={validationSchema}
                onSubmit={_handleSubmit}
              >
                <Fields>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ marginTop: 8 }}>
                      <Input
                        name="name"
                        placeholder="Nome completo"
                        errorPrompt
                        size="large"
                      />
                    </div>
                    <div style={{ marginTop: 8 }}>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        errorPrompt
                        icon="at"
                        size="large"
                      />
                    </div>
                    <div style={{ marginTop: 8 }}>
                      <Input
                        name="cpfCnpj"
                        placeholder="CPF"
                        pattern="[0-9]*"
                        errorPrompt
                        size="large"
                      />
                    </div>

                    <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <SubmitButton
                        style={{
                          color: "#fff",
                        }}
                        size="big"
                        positive
                        loading={loading}
                        fluid
                      >
                        Avançar
                      </SubmitButton>
                    </div>
                  </div>
                </Fields>
              </Formik>
            </div>
            <br />
          </div>
        </animated.div>
      </div>
    </div>
  );
};
