import React, { useContext, useEffect, useState } from "react";
import useWindowDimensions, { Dispatch, Log, navigateTo } from "../hooks/hooks";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  FormField,
  Header,
  Icon,
  Label,
  Menu,
  MenuItem,
  Message,
} from "semantic-ui-react";
import { Validador } from "../components/validador";

export const Home = (props) => {
  const [open, setOpen] = useState(false);
  const { mobile } = useWindowDimensions();
  const reducer = useContext(Dispatch);
  const [{ validado }, dispatch] = reducer;

  const escrito = `O ANO NOVO CHINÉS 2024 INICIA EM FEVEREIRO E VOCÉ PODE CELEBRAR NO TON HOI SERÁ UM EVENTO EM DOIS HORÁRIOS, COM MENU HARMONIZADO COM VINHOS ENTRADAS LIMITADAS! FAÇA AGORA SUA RESERVA`;

  const params = useParams();

  useEffect(() => {
    console.log(params);
    if (params?.qr) {
      props.history.push("/ingresso", { params });
    }
  }, [params]);

  useEffect(() => {
    if (validado) {
      props.history.push("/form");
    }
  }, [validado]);

  const cadastrar = () => {
    const valid = localStorage.getItem("validado");
    if (valid) {
      dispatch({
        type: "validado",
        payload: true,
      });
    } else {
      dispatch({
        type: "modal",
        payload: true,
      });
    }
  };

  return (
    <div className="frame">
      <Validador open={open} />
      <div className="central">
        <div className="pages" style={{ width: mobile ? "100%" : 500 }}>
          <Container fluid>
            <Container textAlign="justified">
              <Header as="h2">Sobre o evento</Header>
              <Divider />
              <div style={{ marginBottom: 12, fontSize: 12 }}>
                <Message warning>
                  Lote vigente pode variar de acordo com a proximidade do evento
                  ou a disponibilidade de convites para venda, o que ocorrer
                  primeiro.
                </Message>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  paddingTop: 10,
                }}
              >
                <Menu compact>
                  <MenuItem as="a" disabled>
                    <div
                      style={{
                        fontSize: 11,
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      a partir de:{" "}
                      <div style={{ fontSize: 14, marginTop: 7 }}>
                        <Icon name="tag" /> R$ 269
                      </div>
                    </div>
                    <Label color="gray" style={{ width: 53 }} floating>
                      Lote 1
                    </Label>
                  </MenuItem>
                </Menu>
                <Menu compact>
                  <MenuItem as="a" disabled>
                    <div
                      style={{
                        fontSize: 11,
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      a partir de:{" "}
                      <div style={{ fontSize: 14, marginTop: 7 }}>
                        <Icon name="tag" /> R$ 314
                      </div>
                    </div>
                    <Label color="gray" style={{ width: 53 }} floating>
                      Lote 2
                    </Label>
                  </MenuItem>
                </Menu>
                <Menu compact>
                  <MenuItem as="a">
                    <div
                      style={{
                        fontSize: 11,
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      a partir de:{" "}
                      <div style={{ fontSize: 14, marginTop: 7 }}>
                        <Icon name="tag" /> R$ 440
                      </div>
                    </div>
                    <Label color="green" style={{ width: 53 }} floating>
                      Lote 3
                    </Label>
                  </MenuItem>
                </Menu>
              </div>
              {/*<Button as="div" labelPosition="right">
                <Button color="green">
                  <Icon name="tag" />1
                </Button>
                <Label as="a" basic color="green" pointing="left">
                  R$ 269
                </Label>
              </Button>
              <Button as="div" labelPosition="right">
                <Button basic>
                  <Icon name="tag" />2
                </Button>
                <Label as="a" basic pointing="left">
                  R$ 314
                </Label>
              </Button>
              <Button as="div" labelPosition="right">
                <Button basic>
                  <Icon name="tag" />3
                </Button>
                <Label as="a" basic pointing="left">
                  R$ 440
                </Label>
              </Button>*/}
              <Divider />
              <p>
                Há mais de 4 milênios os chineses contam os anos através do
                calendário lunar, cujo Ano Novo será regido pelo signo do
                Dragão, comemorando a entrada do ano 4722.
              </p>
              <p>
                Celebraremos aqui no Ton Hoi no dia 09/02/2024 - já pensando no
                fuso horário chinês (que lá já será Ano Novo) - em dois
                períodos: tarde (12h00 às 16h00) e noite (20h00 às 00h00).
                Teremos muitas atrações durante o evento, além de um menu
                harmonizado com 6 rótulos de vinhos pensados com muito carinho
                junto de nossos novos parceiros (tudo isso à vontade!).
              </p>
              <p>
                <b>
                  Garanta agora mesmo seu convite e venha conosco participar
                  deste momento inesquecível!
                </b>
              </p>
            </Container>
          </Container>
          <Button
            style={{ marginTop: 20, marginBottom: 20 }}
            onClick={cadastrar}
            positive
            size="big"
            fluid
          >
            {"Fazer a reserva"}
          </Button>
        </div>
      </div>
    </div>
  );
};
