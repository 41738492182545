import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { toast, Bounce } from "react-toastify";

export const Dispatch = React.createContext("Dispatch");

const logEnabled = true;
const backend = window.location.origin;
//const backend = process.env.REACT_APP_BACKEND_URL;

const urlWhatsappServer = `https://evolution.backup.tonhoi.me/message/sendText/tonhoi-evento`;

const urlBackCadastro = `${backend}/cadastro`;
const urlBackGeraCobranca = `${backend}/gera-cobranca`;

export function reducer(state, action) {
  console.log(
    "action:",
    action.type,
    "action payload:",
    action.payload,
    "state:",
    state
  );
  switch (action.type) {
    case "userdata": {
      return {
        ...state,
        userdata: { ...state.userdata, ...action.payload },
      };
    }
    case "step": {
      return {
        ...state,
        step: action.payload,
      };
    }
    case "validado": {
      return {
        ...state,
        validado: action.payload,
      };
    }
    case "modal": {
      return {
        ...state,
        modal: action.payload,
      };
    }
    case "ingressos": {
      return {
        ...state,
        ingressos: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const Log = (text) => {
  if (logEnabled) {
    console.log(text);
  }
};

const area = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28];

export const sendCelularMessage = async (tel, id, text) => {
  var newNumber = area.includes(Number(tel.toString().substr(2, 2)));

  let numero = "";
  if (newNumber) {
    numero = tel;
  } else {
    numero = tel.toString().substr(0, 4) + tel.toString().substr(5, 9);
  }

  //console.log(numero);

  return new Promise((resolve, reject) => {
    Log(numero, id, text);

    return axios
      .post(
        urlWhatsappServer,
        {
          number: numero,
          options: {
            delay: 1200,
            presence: "composing",
          },
          textMessage: {
            text: text,
          },
        },
        {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            apikey: "8idu7pa2gnpf6fp2bliuq",
          },
        }
      )
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        console.log("erro:", error.response);
        return reject(error);
      });
  });
};

export const postServerCadastro = async (data) => {
  return new Promise((resolve, reject) => {
    console.log(data, urlBackCadastro);

    return axios
      .post(urlBackCadastro, data, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error);
      });
  }).catch((err) => {
    console.log("erro no envio dos dados para o servidor");
  });
};

export const postServerGeraPix = async (data) => {
  return new Promise((resolve, reject) => {
    console.log(data);

    return axios
      .post(urlBackGeraCobranca, data, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error.response);
      });
  }).catch((err) => {
    console.log("erro em gerar a cobrança");
  });
};

export const aviso = (text, type) => {
  toast[type](text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    theme: "light",
    transition: Bounce,
  });
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowDimensions,
    mobile: windowDimensions.width < 500 ? true : false,
  };
}

export const validateCPF = (c, validator) => {
  let inputCPF = c.toString();
  if (inputCPF == "") {
    return true;
  }

  var i;
  var s = inputCPF;
  inputCPF = s.substr(0, 9);
  var dv = s.substr(9, 2);
  var d1 = 0;
  var v = false;

  for (i = 0; i < 9; i++) {
    d1 += inputCPF.charAt(i) * (10 - i);
  }
  if (d1 == 0) {
    v = true;
    return validator.createError({
      message: "Digite um cpf válido",
      path: validator.path,
    });
  }
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  if (dv.charAt(0) != d1) {
    v = true;
    return validator.createError({
      message: "Digite um cpf válido",
      path: validator.path,
    });
  }

  d1 *= 2;
  for (i = 0; i < 9; i++) {
    d1 += inputCPF.charAt(i) * (11 - i);
  }
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  if (dv.charAt(1) != d1) {
    v = true;
    return validator.createError({
      message: "Digite um cpf válido",
      path: validator.path,
    });
  }
  if (!v) {
    return true;
  }
};

export const validateFullName = (value, validator) => {
  const name = value.split(" ");
  if (name.length >= 2) {
    if (name[1] !== "") {
      return true;
    } else {
      return validator.createError({
        message: "Precisa ser o nome completo",
        path: validator.path,
      });
    }
  } else {
    return validator.createError({
      message: "Precisa ser o nome completo",
      path: validator.path,
    });
  }
};
