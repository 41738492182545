import React, { useContext, useEffect, useState } from "react";
import useWindowDimensions, { Dispatch, navigateTo } from "../hooks/hooks";
import { useSpring, animated } from "react-spring";
import Lottie from "react-lottie-player";

import check from "../lottie/1870-check-mark-done.json";
import { Button, Container, Divider, Header } from "semantic-ui-react";

export const Pagamento = (props) => {
  const { mobile } = useWindowDimensions();
  const [play, toggle] = useState(true);

  const reducer = useContext(Dispatch);
  const [{}, dispatch] = reducer;

  const { x, y } = useSpring({
    from: { y: play ? -window.innerHeight : 0, opacity: play ? 0 : 1 },
    to: { y: play ? 0 : -window.innerHeight, opacity: play ? 1 : 0 },
    onRest: () => !play && props.history.push("/home"),
  });

  useEffect(() => {
    dispatch({
      type: "validado",
      payload: false,
    });
  }, []);
  return (
    <div className="frame">
      <div className="central">
        <animated.div
          style={Object.assign({ x, y, width: mobile ? "100%" : 500 })}
        >
          <div className="pages">
            <Container fluid>
              <Header as="h2">Link de pagamento gerado com sucesso!</Header>
              <Divider />
              <div style={{ marginTop: 10, fontSize: 16 }}>
                Você receberá no seu Whatsapp o link para fazer o pagamento do
                seu convite.
              </div>
            </Container>
            <div
              style={{
                fontSize: 20,
                lineHeight: 1,
                color: "#000000",
              }}
            ></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Lottie
                loop={false}
                speed={0.5}
                animationData={check}
                play={true}
                style={{ width: 150, height: 150 }}
              />
            </div>

            <Button
              style={{ marginTop: 10 }}
              primary
              onClick={() => toggle(!play)}
              fluid
            >
              {"Voltar ao início"}
            </Button>
          </div>
        </animated.div>
      </div>
    </div>
  );
};
